import { DateLike, UseDateFormatOptions } from '@vueuse/core'
import { getLanguageCodeFromLocaleCode } from '~/i18n/locales'
import { LocaleCode } from '~/types/locales'

export default function (
  date: MaybeRefOrGetter<DateLike>,
  formatStr?: MaybeRefOrGetter<string> | undefined,
  options?: UseDateFormatOptions | undefined
) {
  const { locale } = useI18n()
  const lang = getLanguageCodeFromLocaleCode(locale.value as LocaleCode)

  return useDateFormat(date, formatStr, { locales: lang, ...options })
}
